import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const HaushaltsrechnungSchrittFuerSchritt = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Deine Haushaltsrechnung – Schritt für Schritt" showCalc={false} />
            <Article>
                <p>
                    Vor jeder größeren Investition ist es ratsam, sich einen Überblick über die eigene finanzielle Lage
                    zu verschaffen. Doch wie geht man das am besten an? Ganz einfach: Mit einer Haushaltsrechnung. Mit
                    dieser kannst du schnell und unkompliziert jenen Teil deines Einkommens bestimmen, der dir zur
                    freien Verfügung steht – den du also nicht für laufende Kosten aufwenden musst, sondern zur
                    Bezahlung deines zukünftigen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    verwenden kannst. In nur drei Schritten kannst du diesen Betrag bestimmen. Wir zeigen dir, wie du
                    dabei vorgehen musst.
                </p>
                <hr />

                <h2>Schritt 1: Monatliche Einnahmen berechnen</h2>
                <p>Zuerst addierst du sämtliche Einkünfte wie</p>
                <ul>
                    <li>dein Nettoeinkommen (Einkommen exklusive Steuern und Sozialabgaben)</li>
                    <li>Einkünfte durch Beihilfen (z.B. Kinder-, Familien- oder Studienbeihilfe)</li>
                    <li>etwaige Mieteinnahmen</li>
                    <li>allfällige Renten</li>
                    <li>Einnahmen durch Aktien, etc.</li>
                </ul>
                <p>
                    Dabei solltest du dir darüber im Klaren sein, dass besonders Beihilfen während der Kreditlaufzeit
                    wegfallen können, wodurch sich dein Einkommen verringern kann.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Schritt 2: Monatliche Ausgaben ermitteln</h2>
                <p>
                    Sobald du sämtliche Einnahmen addiert hast, geht es an den zweiten Schritt: die Berechnung deiner
                    monatlichen Ausgaben. Auch dafür musst du nur die Addition beherrschen – in diesem Fall die deiner
                    Fix- und Lebenshaltungskosten. Zu diesen zählen:
                </p>
                <ul>
                    <li>Miete sowie Betriebs- und Energiekosten</li>
                    <li>Kosten für Telefon, Internet und Rundfunk</li>
                    <li>Mobilitätskosten (Kosten für eigene Fahrzeuge und/oder öffentliche Verkehrsmittel)</li>
                    <li>Versicherungen</li>
                    <li>Abonnements von Zeitschriften, Streamingdiensten etc.</li>
                    <li>Unterhaltszahlungen, Verpflegung und Kleidung</li>
                    <li>und auch alle Ausgaben für deine Hobbies</li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Schritt 3: Eine letzte Subtraktion</h2>
                <p>
                    Im letzten Schritt ziehst du die Summe deiner Ausgaben von der Gesamtheit deiner Einkünfte ab – und
                    voi­là: du hast soeben dein frei verfügbares Einkommen berechnet! Herzlichen Glückwunsch! Ein
                    wichtiger Schritt in der Vorbereitung auf das Verhandlungsgespräch mit der Bank ist getan.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Haushaltsrechnung: Beispiel gefällig?</h2>
                <p>
                    Gehen wir das anhand eines Beispiels durch: Nehmen wir eine beliebige Person, sagen wir den
                    32-jährigen Eduard aus Baden, und führen für ihn eine Haushaltsrechnung durch. Eduard ist seit
                    längerem bei einem größeren österreichischen Unternehmen angestellt und verdient derzeit 2100 €
                    netto pro Monat. Hinzu kommt die ebenfalls monatliche Überweisung von 200 € – ein Geschenk, das
                    Eduard zu seinem 18. Geburtstag von einer Großtante gemacht wurde, die – wie man sich auf
                    Familienfeiern hinter vorgehaltener Hand erzählt - durch die strategische Heirat eines betagten
                    Großindustriellen aus altösterreischischem Adel in den Besitz eines beträchtlichen Vermögens
                    gelangte. Weil die Freigebigkeit von Großtanten bekanntermaßen Grenzen hat und “der Bub” lernen
                    muss, auf eigenen Beinen zu stehen, werden diese Zahlungen mit Eduards 35. Geburtstag jedoch
                    endgültig eingestellt. Bis dahin belaufen sich seine monatlichen Einkünfte also auf 2300 €.
                </p>
                <p>
                    Seine monatlichen Ausgaben setzen sich aus 620 € für Miete inkl. Strom und Gas, 200 € für
                    Lebensmittel, 50 € für den Kombivertrag für Handy und Internet, 365 € für die Jahreskarte der Wiener
                    Linien sowie weiteren ca. 120 € zusammen, die er monatlich für die Pflege einer Kollektion
                    ausgestopfter Kanarienvögel ausgibt. Wir lassen Eduards ungewöhnliche Freizeitaktivität
                    unkommentiert und addieren diese Beträge, was 1355 € an monatlichen Ausgaben ergibt.
                </p>
                <p>
                    Im letzten Schritt ziehen wir die Ausgaben von den Einnahmen ab: 2300-1355= 945. Eduard kann
                    monatlich also auf einen Betrag von 945 € zurückgreifen, den er nicht für die Deckung seiner
                    laufenden Kosten aufwenden muss. Doch sei gewarnt, Eduard: in nur zwei Jahren werden dir monatlich
                    nur noch 745 € zur Verfügung stehen. Wenn er sich also demnächst eine Eigentumswohnung in Wien
                    anschaffen will, um seiner denunziatorischen Badener Verwandtschaft zu entkommen, muss er diesen
                    Umstand bei der Planung seiner Finanzierung unbedingt bedenken.
                </p>
                <hr />

                <h2>Last but not least: Plane Reserven ein!</h2>
                <p>
                    Keinesfalls solltest du den gesamten Betrag deines frei verfügbaren Einkommens für die Finanzierung
                    deiner Kreditrate aufwenden. Plane unbedingt Reserven für unerwartete Ausgaben wie Neuanschaffungen
                    oder Reparaturen ein. Wenn du dich für einen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    mit variablem Zinssatz entscheiden, solltest du zudem für den Fall steigender{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    finanziell gewappnet sein.
                </p>
                <h3>Zusammengefasst</h3>
                <p>
                    Die Haushaltsrechnung ist also im wahrsten Sinne des Wortes kinderleicht. Man muss sie einfach nur
                    machen. Hier nochmal alle Schritte kurz zusammengefasst.
                </p>
                <ol>
                    <li>(Wirklich alle) Einnahmen addieren.</li>
                    <li>(Wirklich alle) Ausgaben addieren.</li>
                    <li>Ausgaben von den Einnahmen abziehen.</li>
                    <li>
                        Deinen Kreditberater mit deiner guten Vorbereitung glücklich machen und so leichter an
                        Top-Konditionen kommen.
                    </li>
                </ol>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"haushaltsrechnungSchrittFuerSchritt"}></BreadcrumbList>
            <ArticleStructuredData
                page={"haushaltsrechnungSchrittFuerSchritt"}
                heading={"Deine Haushaltsrechnung – Schritt für Schritt"}
            />
        </Layout>
    );
};

export default HaushaltsrechnungSchrittFuerSchritt;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.haushaltsrechnung-schritt-fuer-schritt", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
